import React from "react";

const Header = () => {
  return (
    <header>
      <h1>Contact Manager</h1>
    </header>

  );
};

export default Header;
